.root {
    position: relative;
    width: fit-content;
    font-family: Poppins;
    color: white;
    font-weight: 500;
}

.root.draggable {
    border: 1px dashed #ffffff;
}

.container.draggable {
    cursor: move;
}

.draggable > * {
    cursor: move;
}

.close {
    position: absolute;
    /* width: 4rem !important;
    height: 4rem !important;
    top: -2rem;
    right: -2rem; */
    cursor: pointer;
    color: black;
}
